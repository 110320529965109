import PolicyLinkSectionComponent from 'components/policyLink/section';
import React from 'react';
import { BlockComponentProps } from 'strapi/types/utils';

import styles from './PolicyLinkSection.module.scss';

export const PolicyLinkSection = ({
  block,
}: BlockComponentProps<'marketing-website.policy-link-section'>) => {
  const { title } = block;

  return (
    <section className={`mt48 ${styles.container}`}>
      <PolicyLinkSectionComponent
        policies={block.items.map((item) => item.vertical)}
        title={title}
      />
    </section>
  );
};
